import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Container from "../components/Container/Container"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Not found" />
    <Container>
      <h1>Not Found</h1>
      <p>Unfortunately, no page exists at this URL - sorry!</p>
    </Container>
  </Layout>
)

export default NotFoundPage
